import React, {useState, useEffect} from "react";
import { Modal, Row, Col, Button, ConfigProvider, DatePicker, Form, Input, Checkbox, Spin } from "antd";
import { EyeOutlined, EyeInvisibleOutlined, ArrowRightOutlined } from "@ant-design/icons"; 
import { Link, useLocation } from "react-router-dom";
import Logo from "assets/png/logo.png";
import axios from 'axios';
import { Typography } from "antd";
import useStyle from './useStyle';

export const NotFound = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { styles } = useStyle();
  useEffect(async () => {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/verify/link`, {
      verify_link: location.pathname,
      }, {
      headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });

    if (response.data.status == "success") {
      setShowPassword(true);
    }
    // console.log('Current route:', location.pathname);
    // if (location.pathname.includes('cbl')) {
    //   // setCurrentRoute('cbl');
    // } else if (location.pathname.includes('jcbl')) {
    //   // setCurrentRoute('jcbl');
    // } else {  
    //   // setCurrentRoute('academy');
    // }
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    try {
        const formData = new FormData();
        formData.append("password", values.password);
        formData.append("email", localStorage.getItem("email"));

        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}api/change-password`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            }
        );

        if (response.data.status === "success") {
            setTimeout(() => {   
              window.location.href = "/signin";
            }, 1800);
           
        }  else {

        }
    } catch (error) {
        console.error("Changing password error:", 'error');
    } finally {
      console.error("Changing password error:", 'error');
    }
};

const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
};

  return (
    <div>
      {
        !showPassword ? (
          <Typography variant="h2">404: page not found!</Typography>
        ): (
          <div className="client-auth-content">
              <div className="client-auth-card">
                  <div className="mb-30 center">
                      <img src={Logo} alt="Modal Image" />
                  </div>
                  <div>
                    <h3 className="mb-30 text-left">Please type your new password</h3>
                    <ConfigProvider
                      button={{
                        className: styles.linearGradientButton,
                      }}
                    >
                      <Form
                            name="signup"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="New Password"
                                name="password"
                                rules={[{ required: true, message: 'Please enter your password' }]}
                            >
                                <Input.Password
                                    placeholder="New Password"
                                    iconRender={(visible) =>
                                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                    }
                                    visibilityToggle={{
                                        visible: passwordVisible,
                                        onVisibleChange: setPasswordVisible,
                                    }}
                                />
                            </Form.Item>

                            <Form.Item>
                              <Button type="primary" htmlType="submit" block>
                                    {loading ? (
                                        <>
                                            <Spin size="small" style={{ marginRight: 8, color: 'white' }} />
                                            Loading...
                                        </>
                                    ) : (
                                        <>
                                            Change
                                        </>
                                    )}
                              </Button>
                            </Form.Item>
                      </Form>
                    </ConfigProvider>
                  </div>
              </div>
          </div>
        )
      }

    </div>
  );
};