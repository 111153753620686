import React, { useState, useEffect } from 'react';
import { confirmable } from "react-confirm";
import { Modal, Button, Col, Input, Row, Select, message, Typography, Image, notification, Switch } from 'antd';
import Toast from 'layouts/Partials/Toast';
import axios from 'axios';
import dayjs from 'dayjs';
import { capitalizeFirstLetter, formatToDDMMYYYY, isEmpty, openDangerNotification } from "constants/global";

import "./AddSettingDlg.css";
const { TextArea } = Input;

function AddSettingDlg(props) {
    const { show, proceed, title, info, typeList } = props;


    const [formData, setFormData] = useState({});

    const [errorFields, setErrorFields] = useState([]);

    useEffect(() => {
        if (info) {
            setFormData({ ...formData, ...info })
        } else {
            setFormData({
                id: "",
                title: "",
                year_fee: "",
                week_fee: "",
                billing_frequency: "",
                benefit: ""
            })
        }
    }, [info])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (errorFields.includes(name)) {
            let errors = errorFields.filter((x) => x != name);
            setErrorFields([...errors]);
        }
        let tmpVal = value;
        setFormData({ ...formData, [name]: tmpVal });
    };

    const validateFields = () => {
        var errorList = Array();

        errorList = isEmpty(formData, "title", errorList);
        errorList = isEmpty(formData, "year_fee", errorList);
        errorList = isEmpty(formData, "week_fee", errorList);
        errorList = isEmpty(formData, "billing_frequency", errorList);
        errorList = isEmpty(formData, "benefit", errorList);

        setErrorFields([...errorList]);
        return errorList.length == 0;
    }
    const doRegister = async () => {
        if (validateFields()) {
            const formDataToSend = new FormData();
            formDataToSend.append("id", formData.id);
            formDataToSend.append("title", formData.title);
            formDataToSend.append("year_fee", formData.year_fee);
            formDataToSend.append("week_fee", formData.week_fee);
            formDataToSend.append("billing_frequency", formData.billing_frequency);
            formDataToSend.append("benefit", formData.benefit);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/save-subscription-setting`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.status == 200) {
                    notification.success({
                        message: 'Success Notification',
                        description: "Saved successfully!",
                    });
                    proceed(true);
                } else {
                    notification.error({
                        message: 'Error Notification',
                        description: 'Something went wrong',
                    });
                }
            } catch (error) {
                notification.error({
                    message: 'Error Notification',
                    description: 'Something went wrong',
                });
            }
        } else {
            Toast("Please confirm your information again", 2);
        }
    }

    return (
        <Modal
            visible={show}
            destroyOnClose={true}
            onCancel={() => {
                proceed(null);
            }}
            className="add-subscription-setting-modal"
            closable={true}
            maskClosable={false}
            title={title}
            footer={null}
        >
            <div>
                <Row gutter={[16, 16]} align={'middle'} justify={'center'} style={{ marginTop: 20 }}>
                    <Col xs={24} md={24}>
                        <span style={{ paddingBottom: 3 }}>Membership Level</span>
                        <Input
                            name="title"
                            value={formData.title}
                            placeholder="Membership Level"
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("title")
                                    ? `common-input-error`
                                    : `common-input`
                            }
                            onChange={handleInputChange}
                        />

                    </Col>

                    <Col xs={24} md={24}>
                        <span style={{ paddingBottom: 3 }}>Yearly MemberShip Fee</span>
                        <Input
                            name="year_fee"
                            type='number'
                            value={formData.year_fee}
                            placeholder="Yearly MemberShip Fee"
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("year_fee")
                                    ? `common-input-error`
                                    : `common-input`
                            }
                            onChange={handleInputChange}
                        />

                    </Col>
                    <Col xs={24} md={24}>
                        <span style={{ paddingBottom: 3 }}>Weekly Fee</span>
                        <Input
                            name="week_fee"
                            type='number'
                            value={formData.week_fee}
                            placeholder="Weekly Fee"
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("week_fee")
                                    ? `common-input-error`
                                    : `common-input`
                            }
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={24} md={24}>
                        <span style={{ paddingBottom: 3 }}>Billing Frequency</span>
                        <Input
                            name="billing_frequency"
                            type='number'
                            value={formData.billing_frequency}
                            placeholder="Billing Frequency"
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("billing_frequency")
                                    ? `common-input-error`
                                    : `common-input`
                            }
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col xs={24}>
                        <span style={{ paddingBottom: 3 }}>Key Benefits</span>
                        <TextArea
                            name='benefit'
                            value={formData.benefit}
                            rows={8}
                            placeholder="Key Benefits"
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("benefit")
                                    ? `common-input-error`
                                    : `common-input`
                            }
                            onChange={handleInputChange}
                        />
                    </Col>


                </Row>
                <Row justify={'center'} align={'middle'} style={{ marginTop: 20, textAlign: 'center' }}>
                    <Col xs={24} md={24}>
                        <Button type="primary" onClick={doRegister} htmlType="submit" block className="add-user-button">
                            {info ? "Update" : "Add"}
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default confirmable(AddSettingDlg);
