import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Select, Tabs, Modal, message } from 'antd';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import Toast from 'layouts/Partials/Toast';
import '../Dashboard/index.css';
import DataTable from './DataTable';
import { showAddBranchDlg } from './AddBranchDlg/showAddBranchDlg';

const Branch = ({ history }) => {
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/admin/login');
    } else {
      fetchData();
    }
  }, [history]);


  const fetchData = async () => {
    let dataUrl = `${process.env.REACT_APP_API_BASE_URL}api/branch`;
    try {
      const response = await axios.get(dataUrl, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const fetchedData = response.data.map(item => ({
        ...item,
        key: String(item.id),
        image_path_status: item.image_path_status == 'true',
        logo_path_status: item.logo_path_status == 'true',
      }));
      setDataSource(fetchedData);
      setFilteredData(fetchedData);
    } catch (err) {
      Toast('There was an error on server!', 2);
    }
  };

  const handleEdit = async (record) => {
    let dlgRes = await showAddBranchDlg({
      info: record,
      title: "Edit Branch"
    })
    if (dlgRes == null) return
    fetchData();
  };

  const deleteRow = async (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setFilteredData(newData);

    const total = newData.length;
    const current = pagination.current > Math.ceil(total / pagination.pageSize) ? Math.ceil(total / pagination.pageSize) : pagination.current;
    setPagination({ ...pagination, total, current });

    let deleteUrl = `${process.env.REACT_APP_API_BASE_URL}api/branch/${key}`;
    const response = await axios.delete(deleteUrl);

    if (response.data.status == "success") {
      Toast('Deleted successfully!', 1);
    } else {
      Toast('There was an error on server!', 2);
    }
  };


  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => String(val).toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };



  const handleAdd = async () => {
    let dlgRes = await showAddBranchDlg({
      title: "Add Branch"
    })
    if (dlgRes == null) return
    fetchData();
  }
  return (
    <AdminLayout>
      <Row className="table-nav">
        <Col className='d-flex'>
          <Input
            placeholder="Search..."
            value={searchText}
            allowClear={true}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleAdd} style={{ height: '38px' }}>
            Add
          </Button>
        </Col>
      </Row>

      <div style={{ overflowX: 'auto', border: '1px solid #f0f0f0', marginTop: 10, borderRadius: '4px', overflowY: 'hidden' }}>
        <DataTable
          filteredData={filteredData}
          handleEdit={handleEdit}
          deleteRow={deleteRow}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
    </AdminLayout>
  );
};

export default Branch;