import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Select, Tabs, Modal, message } from 'antd';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import Toast from 'layouts/Partials/Toast';
import '../Dashboard/index.css';
import { showAddSettingDlg } from './AddSettingDlg/showAddSettingDlg';
import DataTable from './DataTable';

const SubscriptionSetting = ({ history }) => {
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [title, setTitle] = useState("");

  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const typeList = [
    { value: "CBL", label: "CBL" },
    { value: "JCBL", label: "JCBL" },
    { value: "Academy", label: "Academy" },
    { value: "High School", label: "High School" },
    { value: "United", label: "United" }]


  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/admin/login');
    } else {
      setDataSource([]);
      setFilteredData([]);
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    const formData = new FormData();
    let dataUrl = `${process.env.REACT_APP_API_BASE_URL}api/subscription-setting`;
    try {
      const response = await axios.get(dataUrl, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      const fetchedData = response.data.data.map((item, index) => ({
        ...item,
        key: String(item.id),
      }));
      setDataSource(fetchedData);
      setFilteredData(fetchedData);
    } catch (err) {
      Toast('There was an error on server!', 2);
    }
  };

  const handleEdit = async (record) => {
    let dlgRes = await showAddSettingDlg({
      info: record,
      title: "Edit Subscription Setting"
    })
    if (dlgRes == null) return
    fetchData();
  };

  const deleteRow = async (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setFilteredData(newData);

    const total = newData.length;
    const current = pagination.current > Math.ceil(total / pagination.pageSize) ? Math.ceil(total / pagination.pageSize) : pagination.current;
    setPagination({ ...pagination, total, current });

    let deleteUrl = `${process.env.REACT_APP_API_BASE_URL}api/delete-subscription-setting/${key}`;

    const response = await axios.delete(deleteUrl);

    if (response.data.status == "success") {
      Toast('Deleted successfully!', 1);
    } else {
      Toast('There was an error on server!', 2);
    }
  };


  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => String(val).toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };



  const handleAdd = async () => {

    let dlgRes = await showAddSettingDlg({
      seasons: typeList,
      title: "Add Subscription Setting"
    })
    if (dlgRes == null) return
    fetchData();
  }
  return (
    <AdminLayout>
      <Row className="table-nav">
        <Col className='d-flex'>
          <Input
            placeholder="Search..."
            value={searchText}
            allowClear={true}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleAdd} style={{ height: '38px' }}>
            Add
          </Button>
        </Col>
      </Row>


      <div style={{ overflowX: 'auto', marginTop: 20, border: '1px solid #f0f0f0', borderRadius: '4px', overflowY: 'hidden' }}>
        <DataTable
          filteredData={filteredData}
          handleEdit={handleEdit}
          deleteRow={deleteRow}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>
    </AdminLayout>
  );
};

export default SubscriptionSetting;