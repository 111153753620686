import React from "react";
import { withRouter } from 'react-router-dom';
import AcademyCBATop from "components/AcademyCBA/AcademyCBATop/AcademyCBATop";
import AcademyCBAPrograms from "components/AcademyCBA/AcademyCBAProgram/AcademyCBAProgram";
import AcademyCBAEnrolment from "components/AcademyCBA/AcademyCBAEnrolment/AcademyCBAEnrolment";
import HomeLayout from "layouts/HomeLayout/HomeLayout";

function AcademyCBA(props) {
  return (
    <HomeLayout>
      <AcademyCBATop />
      <AcademyCBAPrograms />
      <AcademyCBAEnrolment />
    </HomeLayout>
  );
}

export default withRouter(AcademyCBA);
