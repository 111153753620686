import React, {useEffect} from "react";
import { withRouter } from 'react-router-dom';
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import imgBg from "assets/png/register-logo2.jpg";
import axios from 'axios';
import './index.css';

function ThankYou(props) {
    const sendEmail = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append('email', localStorage.getItem('thank_email'));
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/send_email/thanks` , formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
    useEffect(()=> {
        //sendEmail();
    }, [])

    return (
        <HomeLayout>
            <div className="thanks-content">
                <div>
                    <h2 style={{color:"#B53A3A"}}>Thank you for Registering with US.</h2>
                    <div className="text-center mt-20">Our support will be emailed to you.</div>
                
                </div>
    
            </div>
            <div class="image-container">
                <img src={imgBg} width='100%' height='auto' />
            </div>
        </HomeLayout>
    );
}

export default withRouter(ThankYou);