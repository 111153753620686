import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { Button, Col, Input, Row, Select, message, Typography, Upload, DatePicker, notification, Form, Spin, ConfigProvider } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import "./Partners.css";
import ImgRegisterLogo from "assets/png/slide-back.jpg"

import axios from 'axios';
import dayjs from 'dayjs';
import moment from "moment";
import { TypeButton } from "components/ButtonWidget/TypeButton/TypeButton";

const { Option } = Select;
const { Text } = Typography;
const { Dragger } = Upload;

const PlayerPartners = ({ history }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [signinResponse, setSigninResponse] = useState("");
    const [signupResponse, setSignupResponse] = useState("");
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(false);

    const [seasonData, setSeasonData] = useState([]);
    const [selectedSeason, setSelectedSeason] = useState("");

    const [buttonList, setButtonList] = useState([
        { text: "CBL", checked: true },
        { text: "JCBL", checked: false },
        { text: "ACADEMY", checked: false },
        { text: "HIGH SCHOOL", checked: false },
        { text: "UNITED", checked: false },
    ])
    const [itemList, setItemList] = useState([])


    useEffect(() => {
        getSeasons();
        fetchData();
    }, [])

    const fetchData = async () => {
        try {
            const formData = new FormData();
            formData.append('type', "");
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/partner-player`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            setItemList(response.data.data);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        } finally {
            setLoading(false);
        }
    };
    const getSeasons = async () => {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/open_season`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        });
        setSeasonData(response.data);
    }
    return (
        <HomeLayout>
            <div className="page-rect">
                <div className='signup-logo'>
                    <img src={ImgRegisterLogo} width="100%" />
                </div>

                <div className='partner-rect'>

                    <div className='partner-title'>
                        <h1>Player Partners</h1>
                    </div>

                    <div className="player-sponsors">
                        <div>
                            <Select
                                showSearch
                                style={{ marginBottom: '11px', width: '250px' }}
                                placeholder="Select a season"
                                value={selectedSeason || undefined}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={seasonData.map((season) => ({
                                    value: season.name,
                                    label: season.name,
                                }))}
                                onChange={async (value) => {
                                    setSelectedSeason(value);  // Set selected season
                                    // const formData = new FormData();
                                    // formData.append('type', title); // ensure 'title' is defined in your scope
                                    // formData.append('season', value);
                                    // formData.append("ui_type", uiType);
                                    // try {
                                    //     // Make an API request
                                    //     const response = await axios.post(
                                    //         `${process.env.REACT_APP_API_BASE_URL}api/individual_player/season-select`,
                                    //         formData,
                                    //         {
                                    //             headers: {
                                    //                 'Content-Type': 'multipart/form-data',
                                    //                 'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                    //             },
                                    //         }
                                    //     );

                                    //     let response_result = [];
                                    //     if (response.data.status === 'success') {
                                    //         response_result = response.data.data;
                                    //     } else {
                                    //         response_result = [];
                                    //     }

                                    //     // Map the response data to include unique keys
                                    //     const fetchedData = response_result.map((item) => ({
                                    //         ...item,
                                    //         key: String(item.id),
                                    //     }));

                                    //     // Update the table data source
                                    //     setDataSource(fetchedData);
                                    //     setFilteredData(fetchedData);
                                    // } catch (error) {
                                    //     console.error('Error fetching data:', error);
                                    // }
                                }}
                            />
                        </div>
                        {itemList.length > 0 ? itemList.map((item, key) => {
                            return <div className="player" key={key}>
                                <h3>{item.name} <span>#{item.point}</span>  / {item.position}</h3>
                                <a href="https://cmtg.com.au/" rel="noopener" target="_blank">
                                    <img className="lazy brand-logo" src={`${process.env.REACT_APP_API_BASE_URL}uploads/media/${item.logo}`} />
                                </a>
                                <div className="player-image">
                                    <img className="lazy" src={`${process.env.REACT_APP_API_BASE_URL}uploads/media/${item.avatar}`} />
                                </div>
                            </div>
                        }) : <div className="no-found-rect">
                            <p className="no-found-txt">No Found</p>
                        </div>}
                    </div>
                </div>
            </div>
        </HomeLayout>
    )
}

export default withRouter(PlayerPartners);