import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import HomeLayout from "layouts/HomeLayout/HomeLayout";
import { Button, Col, Input, Row, Select, message, Typography, Upload, DatePicker, notification, Form, Spin, ConfigProvider } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import "../Register/Register1Page.css";
import ImgRegisterLogo from "assets/png/slide-back.jpg"
import useStyle from './useStyle';
import axios from 'axios';
import dayjs from 'dayjs';
import moment from "moment";

const { Option } = Select;
const { Text } = Typography;
const { Dragger } = Upload;

const SignUp = ({ history }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [signinResponse, setSigninResponse] = useState("");
    const [signupResponse, setSignupResponse] = useState("");
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(false);
    const { styles } = useStyle();

    const onSigninFinish = async (values) => {
        console.log("Form Values:", values);
        try {
            const formData = new FormData();
            formData.append("password", values.password);
            formData.append("email", values.email);

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}api/signin`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('clientToken')}`,
                    },
                }
            );

            if (response.data.status === "success") {
                localStorage.setItem("client_token", response.data.token);
                setIsAuth(true);
            } else {
                setSigninResponse(response.data.message);
            }
        } catch (error) {
            console.error("Signin error:", error);
        }
    };

    const onFinish = async (values) => {
        setSignupResponse("");
        
        if (values.password !== values.cpassword) {
            setPasswordsMatch(false);
            return;
        }

        setPasswordsMatch(true);
        setLoading(true);
        
        try {
            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const userIp = ipResponse.data.ip;

            const formData = new FormData();
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("dateOfBirth", values.dateOfBirth.format("DD-MM-YYYY"));
            formData.append("homeAddress", values.homeAddress);
            formData.append("password", values.password);
            formData.append("email", values.email);
            formData.append("ipAddress", userIp);

            const response = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}api/signup`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            if (response.data.res === "success") {
                localStorage.setItem("email", response.data.email);
                window.location.href = "/otp-verify";
            } else if (response.data.res === "verifying") {
                setSignupResponse(response.data.message);
                setTimeout(() => {
                    window.location.href = "/otp-verify"; // Redirect after 2 seconds
                }, 2000);
            } else if (response.data.res === "exist") {
                setSignupResponse("This account already exists. Please sign in.");
            } else {
                setSignupResponse("We encountered a problem during signup. Please try again in a moment.");
            }
        } catch (error) {
            console.error("Signup error:", error);
        } finally {
            setLoading(false);
        }
        setLoading(false);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    const handleBack = () => {
        window.history.back();
    }

    return (
        <HomeLayout>
            <div style={{ height: 'calc(100vh + 450px)' }}>
                <div className='signup-logo'>
                    <img src={ImgRegisterLogo} width="100%" />
                </div>

                <div className='signup-form'>
                    <h1 className="mb-30">Welcome Here!</h1>
                </div>
                <div className='register-form signup-form'>

                    <div className='register-form-title'>
                        <h1>Your Information</h1>
                    </div>
                    <ConfigProvider
                        button={{
                            className: styles.linearGradientButton,
                        }}
                    >
                        <Form
                            name="signup"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            style={{ padding: '30px 0px', border: '1px solid #dedede;' }}
                        >
                            <div style={{ padding: '0px 38px' }}>
                            </div>
                            <Row className='signup-form-row' gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <h2>First Name</h2>
                                </Col>
                                <Col xs={24} md={18}>
                                    <Form.Item
                                        name="firstName"
                                        rules={[{ required: true, message: "Please enter your first name" }]}
                                    >
                                        <Input placeholder="John" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='signup-form-row' gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <h2>Last Name</h2>
                                </Col>
                                <Col xs={24} md={18}>
                                    <Form.Item
                                        name="lastName"
                                        rules={[{ required: true, message: "Please enter your last name" }]}
                                    >
                                        <Input placeholder="Doe" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='signup-form-row' gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <h2>Date of Birth</h2>
                                </Col>
                                <Col xs={24} md={18}>
                                    <Form.Item
                                        name="dateOfBirth"
                                        rules={[{ required: true, message: "Please select your date of birth" }]}
                                    >
                                        {/* <DatePicker style={{ width: "100%" }} placeholder="Select Date" /> */}
                                        <DatePicker
                                            style={{ width: "100%" }}
                                            placeholder="Select Date"
                                            format="DD-MM-YYYY"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='signup-form-row' gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <h2>Home Address</h2>
                                </Col>
                                <Col xs={24} md={18}>
                                    <Form.Item
                                        name="homeAddress"
                                        rules={[{ required: true, message: "Please enter your home address" }]}
                                    >
                                        <Input.TextArea rows={2} placeholder="123 Main St, City, Country" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='signup-form-row' gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <h2>Email Address</h2>
                                </Col>
                                <Col xs={24} md={18}>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            { required: true, message: "Please enter your email address" },
                                            { type: "email", message: "Please enter a valid email" },
                                        ]}
                                    >
                                        <Input placeholder="john.doe@example.com" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='signup-form-row' gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <h2>Password</h2>
                                </Col>
                                <Col xs={24} md={18}>
                                    <Form.Item
                                        name="password"
                                        validateStatus={passwordsMatch ? '' : 'error'} // Remove icons by leaving the status blank
                                        rules={[{ required: true, message: 'Please enter your password' }]}
                                    >
                                        <Input.Password
                                            placeholder="Password"
                                            iconRender={(visible) =>
                                                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                            }
                                            visibilityToggle={{
                                                visible: passwordVisible,
                                                onVisibleChange: setPasswordVisible,
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='signup-form-row' gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <h2>Confirm Password</h2>
                                </Col>
                                <Col xs={24} md={18}>
                                    <Form.Item
                                        name="cpassword"
                                        validateStatus={passwordsMatch ? '' : 'error'} // Remove icons by leaving the status blank
                                        rules={[
                                            { required: true, message: 'Please confirm your password' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Passwords do not match!'));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            placeholder="Confirm Password"
                                            iconRender={(visible) =>
                                                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                                            }
                                            visibilityToggle={{
                                                visible: confirmPasswordVisible,
                                                onVisibleChange: setConfirmPasswordVisible,
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className='signup-form-row' gutter={[16, 16]}>
                                <Col xs={24} md={6}>
                                    <h2></h2>
                                </Col>
                                <Col xs={24} md={18}>
                                    <Form.Item>
                                        <p style={{ color: 'red', marginBottom: '10px' }}>{signupResponse}</p>
                                        <Button type="primary" htmlType="submit" block style={{ width: '230px', padding: '20px' }}>
                                            {loading ? (
                                                <>
                                                    <Spin size="small" style={{ marginRight: 8, color: 'white' }} />
                                                    Loading...
                                                </>
                                            ) : (
                                                <>
                                                    Next <ArrowRightOutlined />
                                                </>
                                            )}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </ConfigProvider>
                </div>
            </div>
        </HomeLayout>
    )
}

export default withRouter(SignUp);