import React, { Suspense, useEffect } from 'react'
import { FloatButton } from 'antd';
import { BrowserRouter, useLocation } from "react-router-dom";
import { RouterConfig } from "navigation/RouterConfig";
import ReactGA from 'react-ga4';

import "./App.css";

export const initGA = () => {
  ReactGA.initialize('G-MY5LZDCZEG');
};

export const logPageView = () => {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};

export const logEvent = (category, action) => {
  ReactGA.event({
    category,
    action,
  });
};

function App() {
  const TrackPageView = () => {
    const location = useLocation(); 

    useEffect(() => {
      logPageView(); 
    }, [location]);

    return null; 
  };

  useEffect(() => {
    initGA(); 
    logPageView(); 
    console.clear();
  }, []);

  useEffect(() => {
    console.clear(); 
  }, []);

  return (
    <>
      <div className="container" style={{ height: '100vh' }}>
        <BrowserRouter>
          <Suspense>
            <TrackPageView />
            <RouterConfig />
          </Suspense>
        </BrowserRouter>
      </div>

      <FloatButton.BackTop className="backtop-button"  />
      {/* <button className="back-to-top" onClick={() => window.scrollTo(0, 0)}>
        Back to Top
      </button> */}
    </>
  );
}

export default App;