import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Select, Input, DatePicker, Row, Col, Radio, message, Popconfirm, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Toast from 'layouts/Partials/Toast';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs';

import './index.css';

const SeasonSettings = ({ history }) => {
  const { Option } = Select;
  const [seasons, setSeasons] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingSeason, setEditingSeason] = useState(false);
  const [statusValue, setStatusValue] = useState('Open');
  const [leagueValue, setLeagueValue] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const onStatusChange = (e) => {
    setStatusValue(e.target.value);
  };

  const onLeagueChange = (e) => {
    setLeagueValue(e.target.value);
  };

  const handleSubmit = async (values) => {
    const { memberType, seasonName, startDate, endDate, status, registration_start_date, registration_end_date, seasonDetail, uiType, leagueTraing } = values;
    console.log({ values })
    // Check if the dates are valid
    if (!startDate || !endDate || !registration_start_date || !registration_end_date) {
      message.error('Please ensure all dates are valid');
      return;
    }

    const seasonData = {
      memberType,
      seasonName,
      startDate: startDate.isValid() ? startDate.format('DD-MM-YYYY') : null,
      endDate: endDate.isValid() ? endDate.format('DD-MM-YYYY') : null,
      status: status === 'Open' ? '1' : '0',
      registration_start_date: registration_start_date.isValid() ? registration_start_date.format('DD-MM-YYYY') : null,
      registration_end_date: registration_end_date.isValid() ? registration_end_date.format('DD-MM-YYYY') : null,
      seasonDetail,
      leagueTraing: leagueTraing == 'Applicable' ? '1' : '0',
      uiType
    };

    try {
      if (editingSeason) {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/season/${editingSeason.key}`, seasonData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        const tmpSeasonData = {
          memberType,
          seasonName,
          startDate: startDate.isValid() ? startDate.format('YYYY-MM-DD') : null,
          endDate: endDate.isValid() ? endDate.format('YYYY-MM-DD') : null,
          status: status === 'Open' ? '1' : '0',
          registration_start_date: registration_start_date.isValid() ? registration_start_date.format('YYYY-MM-DD') : null,
          registration_end_date: registration_end_date.isValid() ? registration_end_date.format('YYYY-MM-DD') : null,
          seasonDetail,
          leagueTraing: leagueTraing == 'Applicable' ? '1' : '0',
          uiType
        };

        setSeasons(seasons.map(season =>
          season.key === editingSeason.key ? { ...season, ...tmpSeasonData } : season
        ));

        setDataSource(dataSource.map(season =>
          season.key === editingSeason.key ? { ...season, ...tmpSeasonData } : season
        ));
        resetForm();
        Toast('Season updated successfully', 1);
      } else {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/season`, seasonData, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        const tmpSeasonData = {
          key: response.data.id,
          memberType,
          seasonName,
          startDate: startDate.isValid() ? startDate.format('YYYY-MM-DD') : null,
          endDate: endDate.isValid() ? endDate.format('YYYY-MM-DD') : null,
          status: status === 'Open' ? '1' : '0',
          registration_start_date: registration_start_date.isValid() ? registration_start_date.format('YYYY-MM-DD') : null,
          registration_end_date: registration_end_date.isValid() ? registration_end_date.format('YYYY-MM-DD') : null,
          seasonDetail,
          leagueTraing: leagueTraing == 'Applicable' ? '1' : '0',
          uiType
        };

        // Uncomment this if you want to directly update the UI with the new season data
        setSeasons(prevSeasons => [...prevSeasons, tmpSeasonData]);
        setDataSource(prevDataSource => [...prevDataSource, tmpSeasonData]);
        resetForm();
        Toast('Season created successfully', 1);
      }

      resetForm();
    } catch (error) {
      Toast('Failed to save season. Please try again.', 2);
      console.error('Error saving season:', error);
    }
  };



  const deleteRow = async (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setPagination((prevPagination) => ({
      ...prevPagination,
      total: newData.length,
      current: Math.ceil(newData.length / prevPagination.pageSize),
    }));

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/season/${key}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.status === "success") {
        Toast('Deleted successfully!', 1);
      } else {
        Toast('There was an error on the server!', 2);
      }
    } catch (error) {
      Toast('Failed to delete season. Please try again.', 2);
      console.error('Error deleting season:', error);
    }
  };

  const resetForm = () => {
    setEditingSeason(null);
    setIsModalVisible(false);
    setStatusValue('Open');
  };

  const handleEdit = (record) => {
    console.log(record);
    setEditingSeason(record);
    setIsModalVisible(true);
  };

  // const handleDateChange = (date, fieldName) => {
  //   alert("d");
  //   // setEditingSeason({
  //   //   ...editingSeason,
  //   //   [fieldName]: date ? moment(date) : null, // Update the date field in the editingSeason state
  //   // });
  // };
  // const handleDateChange = (date, dateString, fieldName) => {
  //   setEditingSeason(prev => ({
  //     ...prev,
  //     [fieldName]: dateString ? dayjs(dateString) : null,  // Update specific field based on name
  //   }));
  // };

  const columns = [
    {
      title: 'Type',
      dataIndex: 'memberType',
    },
    {
      title: 'Season Name',
      dataIndex: 'seasonName',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (date) => date ? moment(date).format('DD-MM-YYYY') : '',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: (date) => date ? moment(date).format('DD-MM-YYYY') : '',
    },
    {
      title: 'Registration Start Date',
      dataIndex: 'registration_start_date',
      render: (date) => date ? moment(date).format('DD-MM-YYYY') : '',
    },
    {
      title: 'Registration End Date',
      dataIndex: 'registration_end_date',
      render: (date) => date ? moment(date).format('DD-MM-YYYY') : '',
    },
    // {
    //   title: 'Detail',
    //   dataIndex: 'seasonDetail',
    //   render: (text) => {
    //     const isLongText = text.length > 20;
    //     const displayText = isLongText ? `${text.substring(0, 20)}...` : text;

    //     return (
    //       <Tooltip title={text}>
    //         <p style={{ margin: 0 }}>{displayText}</p>
    //       </Tooltip>
    //     );
    //   },
    // },

    {
      title: 'UI Type',
      dataIndex: 'uiType',
    },
    {
      title: 'League Traings',
      dataIndex: 'leagueTraing',
      render: (_, record) => (
        <span>{record.leagueTraing == '1' ? 'Applicable' : 'Not Applicable'}</span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => (
        <span>{record.status === '1' ? 'Open' : 'Close'}</span>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        return (
          <>
            <Button onClick={() => handleEdit(record)} className="btn-edit-season">
              <EditOutlined />
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
              <Button type="link" className="btn-admin-delete"><DeleteOutlined /></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/admin/login');
    } else {
      fetchData();
    }
  }, []);
  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/season`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const fetchedSeasons = response.data
        .filter(item => item.is_deleted != 1)
        .map(item => ({
          key: item.id,
          memberType: item.type,
          seasonName: item.name || '',
          startDate: item.start_date ? moment(item.start_date) : null,
          endDate: item.end_date ? moment(item.end_date) : null,
          status: item.status,
          registration_start_date: item.registration_start_date ? moment(item.registration_start_date) : null,
          registration_end_date: item.registration_end_date ? moment(item.registration_end_date) : null,
          seasonDetail: item.detail,
          uiType: item.ui_type,
          leagueTraing: item.league_traing
        }));

      setSeasons(fetchedSeasons);
      setDataSource(fetchedSeasons);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: fetchedSeasons.length,
      }));
    } catch (error) {
      Toast('Failed to fetch data. Please try again later.', 2);
    }
  };
  const handleFocus = () => {
    console.log('Input field clicked, clearing value');
    setEditingSeason(prev => ({ ...prev, startDate: null }));

  };

  const handleDateChange = (date, dateString, fieldName) => {
    setEditingSeason(prev => ({
      ...prev,
      [fieldName]: dateString ? dayjs(dateString) : null,  // Update specific field based on name
    }));
  };

  const parseDate = (date) => {
    // If it's already a dayjs object, just return it; if it's a string, parse it
    return date ? (dayjs.isDayjs(date) ? date : dayjs(date)) : null;
  };

  const handleAddSeason = () => {
    setEditingSeason(null);
    resetForm();
    setIsModalVisible(true);
    if (editingSeason) {

    }
  }


  return (
    <AdminLayout>
      <Row className="d-flex justify-end">
        <Col>
          <Button type="primary" onClick={handleAddSeason} style={{ height: '44px', marginLeft: '10px' }}>
            Add Season
          </Button>
        </Col>
      </Row>

      <Table
        dataSource={dataSource}
        columns={columns}
        style={{ marginTop: '16px' }}
        pagination={pagination}
      />

      <Modal
        key={editingSeason?.key}
        title={editingSeason ? "Edit Season" : "Add Season"}
        visible={isModalVisible}
        onCancel={resetForm}
        width="1000px"
        footer={null}
      >
        <Form
          onFinish={handleSubmit}
          initialValues={{
            memberType: editingSeason?.memberType || '',
            seasonName: editingSeason?.seasonName || '',
            startDate: parseDate(editingSeason?.startDate),
            endDate: parseDate(editingSeason?.endDate),
            seasonDetail: editingSeason?.seasonDetail || '',
            uiType: editingSeason?.uiType || '',
            registration_start_date: parseDate(editingSeason?.registration_start_date),
            registration_end_date: parseDate(editingSeason?.registration_end_date),
            leagueTraing: editingSeason
              ? editingSeason.leagueTraing === '1'
                ? 'Applicable'
                : 'Not Applicable'
              : 'Applicable',
            status: editingSeason
              ? editingSeason.status === '1'
                ? 'Open'
                : 'Close'
              : 'Open',
          }}
          className="mt-20 admin-season"
        >
          <Row gutter={[4, 4]}>
            <Col sm={12}><Form.Item
              label="Set Type"
              name="memberType"
              rules={[{ required: true, message: '' }]}
            >
              <Select placeholder="Select Type">
                <Option value="CBL">CBL</Option>
                <Option value="JCBL">JCBL</Option>
                <Option value="High School">High School</Option>
                <Option value="Academy">Academy</Option>
              </Select>
            </Form.Item></Col>
            <Col sm={12}><Form.Item
              label="Season Name"
              name="seasonName"
              rules={[{ required: true, message: '' }]}
            >
              <Input placeholder="Enter Season Name" />
            </Form.Item></Col>
            <Col sm={12}><Form.Item
              label="Season Start Date"
              name="startDate"
              rules={[{ required: true, message: 'Start Date is required' }]}
            >
              <DatePicker
                placeholder="Select Start Date"
                format="DD-MM-YYYY"
                value={editingSeason?.startDate ? moment(editingSeason.startDate, "YYYY-MM-DD") : null}
              />
            </Form.Item></Col>
            <Col sm={12}><Form.Item
              label="Season End Date"
              name="endDate"
              rules={[{ required: true, message: 'End Date is required' }]}
            >
              <DatePicker
                placeholder="Select End Date"
                format="DD-MM-YYYY"
                value={editingSeason?.endDate ? moment(editingSeason.endDate, "YYYY-MM-DD") : null}
              />
            </Form.Item></Col>
            <Col sm={12}><Form.Item
              label="Registration Start Date"
              name="registration_start_date"
              rules={[{ required: true, message: 'Registration Start Date is required' }]}
            >
              <DatePicker
                placeholder="Select Registration Start Date"
                format="DD-MM-YYYY"
                value={editingSeason?.registration_start_date ? moment(editingSeason.registration_start_date, "YYYY-MM-DD") : null}
              />
            </Form.Item></Col>
            <Col sm={12}><Form.Item
              label="Registration End Date"
              name="registration_end_date"
              rules={[{ required: true, message: 'Registration End Date is required' }]}
            >
              <DatePicker
                placeholder="Select Registration End Date"
                format="DD-MM-YYYY"
                value={editingSeason?.registration_end_date ? moment(editingSeason.registration_end_date, "YYYY-MM-DD") : null}
              />
            </Form.Item></Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Form.Item
                label="Detail information"
                name="seasonDetail"
              >
                <Input.TextArea rows={5} placeholder="" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[4, 4]}>
            <Col sm={12}><Form.Item
              label="UI Type"
              name="uiType"
              rules={[{ required: true, message: '' }]}
            >
              <Select placeholder="Select UI Type">
                <Option value="Individual">Individual</Option>
                <Option value="Team">Team</Option>
              </Select>
            </Form.Item></Col>

            <Col sm={12}>
              <Form.Item
                label="League Traings"
                name="leagueTraing"
                rules={[{ required: true, message: '' }]}
              >
                <Radio.Group value={editingSeason?.leagueTraing == '1' ? 'Applicable' : 'Not Applicable'} onChange={onLeagueChange}>
                  <Radio value="Applicable">Applicable</Radio>
                  <Radio value="Not Applicable">Not Applicable</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Status" name="status">
            <Radio.Group value={editingSeason?.status === '1' ? 'Open' : 'Close'} onChange={onStatusChange}>
              <Radio value="Open">Open</Radio>
              <Radio value="Close">Close</Radio>
            </Radio.Group>
          </Form.Item>


          <Form.Item>
            <div className="d-flex justify-end">
              <Button type="primary" htmlType="submit">Save</Button>
              <Button style={{ marginLeft: '8px' }} onClick={resetForm}>Cancel</Button>
            </div>
          </Form.Item>


















        </Form>
      </Modal>
    </AdminLayout>
  );
};

export default SeasonSettings;