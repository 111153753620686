import React, { useState, useEffect } from 'react';
import { confirmable } from "react-confirm";
import { Modal, Button, Col, Input, Row, Select, message, Typography, Upload, DatePicker, Form, notification, Switch } from 'antd';
import Toast from 'layouts/Partials/Toast';
import axios from 'axios';
import dayjs from 'dayjs';
import { capitalizeFirstLetter, formatToDDMMYYYY, isEmpty, openDangerNotification } from "constants/global";

import "./AddUserDlg.css";
const { Option } = Select;
const { Text } = Typography;

function AddUserDlg(props) {
    const { show, proceed, title, userData } = props;


    const [formData, setFormData] = useState({});

    const [errorFields, setErrorFields] = useState([]);

    useEffect(() => {
        if (userData) {
            setFormData({ ...formData, ...userData, is_default_password: false, password: "" })
        } else {
            setFormData({
                id: '',
                first_name: '',
                last_name: '',
                birthday: '',
                address: '',
                email: '',
                password: '',
                confirm_password: '',
                is_default_password: false
            })
        }
    }, [userData])

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (errorFields.includes(name)) {
            let errors = errorFields.filter((x) => x != name);
            setErrorFields([...errors]);
        }
        let tmpVal = value;
        if (name.includes("name")) {
            tmpVal = capitalizeFirstLetter(value)
        } else {
            tmpVal = value
        }

        setFormData({ ...formData, [name]: tmpVal });
    };

    const validateFields = () => {
        var errorList = Array();

        errorList = isEmpty(formData, "first_name", errorList);
        errorList = isEmpty(formData, "last_name", errorList);
        errorList = isEmpty(formData, "birthday", errorList);
        errorList = isEmpty(formData, "address", errorList);
        errorList = isEmpty(formData, "email", errorList);

        if (formData.is_default_password) {
            errorList = isEmpty(formData, "password", errorList);
            errorList = isEmpty(formData, "confirm_password", errorList);
            if (formData.password != formData.confirm_password) {
                errorList = [...errorList, "confirm_password"]
            }
        }
        setErrorFields([...errorList]);
        return errorList.length == 0;
    }
    const doRegister = async () => {
        if (validateFields()) {

            const ipResponse = await axios.get('https://api.ipify.org?format=json');
            const userIp = ipResponse.data.ip;

            const formDataToSend = new FormData();
            formDataToSend.append("id", formData.id);
            formDataToSend.append("firstName", formData.first_name);
            formDataToSend.append("lastName", formData.last_name);
            formDataToSend.append("dateOfBirth", formData.birthday);
            formDataToSend.append("homeAddress", formData.address);
            formDataToSend.append("password", formData.password);
            formDataToSend.append("email", formData.email);
            formDataToSend.append("ipAddress", userIp);
            formDataToSend.append("is_default_password", formData.is_default_password);

            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/user/create_by_admin`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.status === 200) {
                    proceed(true);
                } else {
                    openDangerNotification(2);
                }
            } catch (error) {
                openDangerNotification(3);
            }
        } else {
            Toast("Please confirm your information again", 2);
        }
    }

    return (
        <Modal
            visible={show}
            destroyOnClose={true}
            onCancel={() => {
                proceed(null);
            }}
            className="add-user-modal"
            closable={true}
            maskClosable={false}
            title={title}
            footer={null}
        >
            <div>
                <Row gutter={[16, 16]} align={'middle'} justify={'center'}>
                    <Col xs={24} md={12}>
                        <Input
                            name="first_name"
                            value={formData.first_name}
                            placeholder="FIRST NAME"
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("first_name")
                                    ? `common-input-error`
                                    : `common-input`
                            }
                            onChange={handleInputChange}
                        />

                    </Col>
                    <Col xs={24} md={12}>
                        <Input
                            name="last_name"
                            value={formData.last_name}
                            placeholder="LAST NAME"
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("last_name")
                                    ? `common-input-error`
                                    : `common-input`
                            }
                            onChange={handleInputChange}
                        />

                    </Col>
                    <Col xs={24} md={12}>
                        <Input
                            name="address"
                            value={formData.address}
                            placeholder="ADDRESS"
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("address")
                                    ? `common-input-error`
                                    : `common-input`
                            }
                            onChange={handleInputChange}
                        />

                    </Col>

                    <Col xs={24} md={12}>
                        <DatePicker
                            placeholder="DATE OF BIRTH"
                            format="DD-MM-YYYY"
                            allowClear={false}
                            value={
                                formData.birthday == "" ? "" : dayjs(formData.birthday, "DD-MM-YYYY")
                            }
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("birthday")
                                    ? `date-picker common-input-error`
                                    : `date-picker common-input`
                            }
                            onChange={(v, str) => {
                                if (errorFields.includes("birthday")) {
                                    let errors = errorFields.filter((x) => x != "birthday");
                                    setErrorFields([...errors]);
                                }

                                setFormData({ ...formData, birthday: str })

                            }}
                        />

                    </Col>
                    <Col xs={24} md={12}>
                        <Input
                            name="email"
                            type='email'
                            autoComplete='off'
                            value={formData.email}
                            placeholder="EMAIL ADDRESS"
                            className={
                                errorFields !== undefined &&
                                    errorFields.includes("email")
                                    ? `common-input-error`
                                    : `common-input`
                            }
                            onChange={handleInputChange}
                        />

                    </Col>
                    <Col xs={24} md={12}></Col>

                    <Col xs={24} md={24}>
                        {userData ? <span>Reset Password</span> : <span>Set Password (Default Password: 123456)</span>}
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            checked={formData.is_default_password}
                            onChange={(e) => {
                                setFormData({ ...formData, is_default_password: e })
                            }} />
                    </Col>

                    {formData.is_default_password && <>
                        <Col xs={24} md={12}>
                            <Input
                                name="password"
                                type='password'
                                autoComplete='off'
                                value={formData.password}
                                placeholder="PASSWORD"
                                className={
                                    errorFields !== undefined &&
                                        errorFields.includes("password")
                                        ? `common-input-error`
                                        : `common-input`
                                }
                                onChange={handleInputChange}
                            />

                        </Col>
                        <Col xs={24} md={12}>
                            <Input
                                name="confirm_password"
                                type='password'
                                value={formData.confirm_password}
                                placeholder="CONFIRM PASSWORD"
                                className={
                                    errorFields !== undefined &&
                                        errorFields.includes("confirm_password")
                                        ? `common-input-error`
                                        : `common-input`
                                }
                                onChange={handleInputChange}
                            />
                        </Col>
                    </>}

                </Row>
                <Row justify={'center'} align={'middle'} style={{ marginTop: 20, textAlign: 'center' }}>
                    <Col xs={24} md={24}>
                        <Button type="primary" onClick={doRegister} htmlType="submit" block className="add-user-button">
                            {userData ? "Update" : "Add"}
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default confirmable(AddUserDlg);
